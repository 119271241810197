
import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  watch,
  computed,
  nextTick,
} from "vue";
import { getOrganization } from "@/core/services/JwtService";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Multiselect from "@vueform/multiselect";
import DecimalInput from "@/components/ABilling/DecimalInput.vue";
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, helpers, maxValue, maxLength } from "@vuelidate/validators";
import { useRoute, useRouter } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { searchDictInsuranceCompanys } from "@/api/code-master-insurance-company.api";
import { getInsurancePayment } from "@/api/insurancePayment.api";
import { PatientInsurance } from "@/modules/patientPayer/patientPayer.model";
import NoteComponent from "@/modules/note/NotesComponent.vue";
import { epsilon, subNumbers, sumNumbers } from "@/utility";
import DateFloatComponent from "@/components/ABilling/DateFloatComponent.vue";
import InsurancePaymentsRefundSearchComponent from "@/modules/payment/InsurancePaymentsRefundSearch.vue";
import InsurancePaymentsRefundViewComponent from "@/modules/payment/InsurancePaymentsRefundView.vue";
import { addInsurancePaymentRefund } from "@/api/insurancePaymentRefund.api";
import { getPanel } from "@/api/encounter.api";

export interface RevenueCode {
  code: string;
  description: string;
  chargePerUnit: number;
}

export interface Data {
  refundModal: boolean;
  modal;
  savingInProgress: boolean;
  isDirty: boolean;
  isLoaded: boolean;
  payment?: any;
  previousPayment?: any;
  createNewMode: boolean;
  dict: {
    facilities: any[];
    waystarList: PatientInsurance[];
    paymentTypes: any[];
  };
}

export default defineComponent({
  name: "InsurancePaymentRefund",
  components: {
    Multiselect,
    DecimalInput,
    NoteComponent,
    DateFloatComponent,
    InsurancePaymentsRefundSearchComponent,
    InsurancePaymentsRefundViewComponent,
  },
  props: ["paymentId"],
  beforeRouteLeave(to, from, next) {
    if (this.data.isDirty) {
      let text = "Are you sure you want to leave without saving changes?";
      Swal.fire({
        title: text,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        denyButtonText: "No",
        allowOutsideClick: false,
        customClass: {
          cancelButton: "ab-button-secondary",
          confirmButton: "ab-button",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          next();
        } else if (result.isDismissed) {
          next(false);
        }
      });
    } else {
      next();
    }
  },
  setup(props, ctx) {
    const store = useStore();
    let organizationId = ref<string | null>("");
    const serviceHistoryPayment = ref(null);
    const router = useRouter();
    const route = useRoute();
    let data = reactive<Data>({
      refundModal: false,
      modal: false,
      savingInProgress: false,
      isDirty: false,
      isLoaded: false,
      payment: undefined,
      createNewMode: true,
      dict: {
        facilities: [],
        waystarList: [],
        paymentTypes: [
          { id: 0, name: "Check" },
          { id: 1, name: "EFT" },
          { id: 2, name: "Virtual Card" },
        ],
      },
    });

    watch(
      () => data.payment?.payerId,
      (currentValue, oldValue) => {
        if (!data.payment?.isPosted) {
          data.payment.insurancePaymentRefunds = [];
          calculateAmount();
        }
      }
    );

    watch(
      () => data.payment,
      (currentValue, oldValue) => {
        if (
          currentValue &&
          data.previousPayment &&
          JSON.stringify(currentValue) != JSON.stringify(data.previousPayment)
        ) {
          data.isDirty = true;
        }
      },
      { deep: true }
    );

    onMounted(async () => {
      organizationId.value = getOrganization();
      data.dict.waystarList = await searchDictInsuranceCompanys({ search: "" });
      const panel = await getPanel();
      data.dict.facilities = panel.facilities;

      setCurrentPageBreadcrumbs("Refund for Insurance Unapplied Credits", [
        { buttonTitle: "Back", path: "back" },
      ]);

      if (props.paymentId) {
        data.payment = await getInsurancePayment(props.paymentId);
        data.createNewMode = false;
      } else {
        data.payment = {
          paymentDate: getUTCnow().toISOString(),
          accountingDate: getUTCnow().toISOString(),
          amount: null,
          insurancePaymentRefunds: [],
        };

        data.createNewMode = true;
      }
      data.previousPayment = JSON.parse(JSON.stringify(data.payment));
      data.isLoaded = true;
      data.isDirty = false;
    });

    function getUTCnow() {
      var date = new Date();
      const d = new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
      );
      return d;
    }

    const paymentRules = {
      payment: {
        facilityId: { required: helpers.withMessage("Required", required) },
        payerId: {
          req: helpers.withMessage("Required", required),
        },
        paymentType: {
          req: helpers.withMessage("Required", required),
        },
        paymentDate: {
          paymentReq: helpers.withMessage("Required", required),
        },
        amount: {
          req: helpers.withMessage("Required", required),
          maxValue: maxValue(9999999999),
        },
        checkRemittanceNumber: {
          req: helpers.withMessage("Required", required),
          maxLength: maxLength(50),
        },
      },
    };

    let v$ = useVuelidate(paymentRules, data as never) as any;

    const maxServDate = computed(() => {
      return getNow();
    });

    function getNow() {
      var date = new Date();
      date.setHours(0, 0, 0, 0);
      return date;
    }

    async function savePayment() {
      let validateResults = await v$.value.$validate();
      if (validateResults) {
        data.savingInProgress = true;

        if (data.createNewMode) {
          data.payment.id = await addInsurancePaymentRefund(data.payment);
          data.createNewMode = false;
        }

        data.payment = await getInsurancePayment(data.payment.id);
      }
      data.previousPayment = JSON.parse(JSON.stringify(data.payment));
      data.isDirty = false;
      data.savingInProgress = false;
    }

    function cancel() {
      router.go(-1);
    }

    function selectNotes() {
      data.modal = true;
    }

    function amountChanged(payment) {
      let refund = data.payment.insurancePaymentRefunds.find((item) => {
        return item.insurancePaymentFromId == payment.id;
      });
      if (refund) {
        refund.unappliedCreaditRefundAmount = payment.refundAmount;
        if (!payment.refundAmount) {
          const index = data.payment.insurancePaymentRefunds.indexOf(refund, 0);
          if (index > -1) {
            data.payment.insurancePaymentRefunds.splice(index, 1);
          }
        }
      } else if (payment.refundAmount > 0) {
        let insurancePaymentRefund = {
          insurancePaymentFromId: payment.id,
          unappliedCreaditRefundAmount: payment.refundAmount,
        };
        data.payment.insurancePaymentRefunds.push(insurancePaymentRefund);
      }

      calculateAmount();
    }

    function calculateAmount() {
      let amount = 0;

      data.payment.insurancePaymentRefunds.forEach((item) => {
        amount = sumNumbers(amount, item.unappliedCreaditRefundAmount);
      });

      data.payment.amount = amount;
    }

    return {
      amountChanged,
      organizationId,
      data,
      maxServDate,
      epsilon,
      getNow,
      cancel,
      savePayment,
      selectNotes,
      subNumbers,
      sumNumbers,
      serviceHistoryPayment,
      v$,
    };
  },
});
