import ApiService from "@/core/services/ApiService";
import { processErrors } from "./errorsProcessing";

export const url = "api/v1/insurancePaymentRefund";

export async function addInsurancePaymentRefund(payment) {
  return await ApiService.post(url, payment as never)
    .then((res) => {
      return res.data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}
