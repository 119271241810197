
import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  nextTick,
  watch,
} from "vue";
import { searchInsurancePayments } from "@/api/insurancePayment.api";
import PaginationUi from "@/components/ABilling/Pagination.vue";
import DecimalInput from "@/components/ABilling/DecimalInput.vue";
import debounce from "lodash.debounce";
import useFilters from "@/modules/common/useFilters";

export interface Pagination {
  currentPage: number;
  totalPages: number;
  totalCount: number;
  pageSize: number;
}
export interface StatusCode {
  code: number;
  description: string;
}
export interface Data {
  items: any[];
  keyword: string;
  pagination: Pagination;
  dict: any;
}
export default defineComponent({
  name: "InsurancePaymentsRefundSearch",
  components: {
    PaginationUi,
    DecimalInput,
  },
  props: ["payerId", "insurancePaymentRefunds"],
  setup(props, ctx) {
    let organizationId = ref<string | null>("");

    let data = reactive<Data>({
      items: [],
      keyword: "",
      pagination: {
        currentPage: 1,
        totalPages: 0,
        totalCount: 0,
        pageSize: 10,
      },
      dict: {
        paymentTypes: [
          { id: 0, name: "Check" },
          { id: 1, name: "EFT" },
          { id: 2, name: "Virtual Card" },
        ],
      },
    });

    watch(
      () => props.payerId,
      async (currentValue, oldValue) => {
        await getAll();
      }
    );

    async function cleanFilter() {
      data.keyword = "";
      await getAll();
    }

    onMounted(async () => {
      await getAll();
    });

    async function debounceSearch() {
      await debounce(search, useFilters().debounceMs)();
    }

    async function search() {
      await nextTick();
      data.pagination.currentPage = 1;
      await getAll();
    }

    async function getAll() {
      let request = {
        keyword: data.keyword,
        pageNumber: data.pagination.currentPage,
        pageSize: data.pagination.pageSize,
        isPosted: true,
        isUnappliedCredit: true,
        payerId: props.payerId,
      };

      const res = await searchInsurancePayments(request);
      data.items = res.data;
      data.pagination.currentPage = res.currentPage;
      data.pagination.totalPages = res.totalPages;
      data.pagination.totalCount = res.totalCount;
      data.pagination.pageSize = res.pageSize;

      props.insurancePaymentRefunds.forEach((item) => {
        let payment = data.items.find((el) => {
          return el.id == item.insurancePaymentFromId;
        });
        if (payment) payment.refundAmount = item.unappliedCreaditRefundAmount;
      });
    }

    async function clearSearch(header) {
      header.keyword = null;
      await search();
    }

    async function pageChanged(page) {
      data.pagination.currentPage = page;
      await getAll();
    }

    async function pageSizeChanged(pageSize) {
      data.pagination.pageSize = pageSize;
      data.pagination.currentPage = 1;
      await getAll();
    }

    function getNow() {
      var date = new Date();
      date.setHours(0, 0, 0, 0);
      return date;
    }

    async function amountChanged(insurancePayment) {
      await nextTick();
      ctx.emit("amountChanged", insurancePayment);
    }

    return {
      amountChanged,
      organizationId,
      clearSearch,
      data,
      debounceSearch,
      search,
      cleanFilter,
      pageChanged,
      pageSizeChanged,
      getNow,
    };
  },
});
