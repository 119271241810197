
import { defineComponent, onMounted, ref, reactive } from "vue";

export interface Pagination {
  currentPage: number;
  totalPages: number;
  totalCount: number;
  pageSize: number;
}
export interface StatusCode {
  code: number;
  description: string;
}
export interface Data {
  items: any[];
  dict: any;
}
export default defineComponent({
  name: "InsurancePaymentsRefundSearch",
  components: {},
  props: ["payerId", "insurancePaymentRefunds"],
  setup(props, ctx) {
    let organizationId = ref<string | null>("");

    let data = reactive<Data>({
      items: [],
      dict: {
        paymentTypes: [
          { id: 0, name: "Check" },
          { id: 1, name: "EFT" },
          { id: 2, name: "Virtual Card" },
        ],
      },
    });

    onMounted(async () => {
      data.items = props.insurancePaymentRefunds;
    });

    return {
      organizationId,
      data,
    };
  },
});
